<template>
  <div class="topicMore">
    <div class="back flex-center-center" @click="$router.go(-1)">
      <svg-icon icon-class="headArrow" />
    </div>
    <!-- tab -->
    <van-tabs v-model="activeName" swipeable class="homeTab" title-active-color="rgb(0,0,0)" title-inactive-color="rgb(142,142,142)" color="#ff0d2c" animated :lazy-render="false">
      <van-tab :title="item.label" v-for="item in tabList" :key="item.value">
        <VideoList :topicId="topicId" :type="item.value" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import VideoList from './videoList/index.vue';

export default {
  name: 'topicMore',
  components: {
    VideoList,
  },
  data() {
    return {
      topicId: '',
      activeName: 'new',
      tabList: [
        {
          label: '最新',
          value: 'new',
        },
        {
          label: '最热',
          value: 'hot',
        },
      ], // tab列表
    };
  },
  created() {
    this.topicId = this.$route.query.topicId;
  },
};
</script>
<style lang="scss" scoped>
.topicMore {
  height: 100%;
  .back {
    position: absolute;
    left: 14px;
    top: 14px;
    height: 19px;
    width: 19px;
    z-index: 1;
    > svg {
      height: 19px;
      width: 19px;
    }
  }
}
/deep/ .van-tabs {
  .van-tabs__wrap {
    padding: 0 130px;
  }
  .van-tab__text {
    font-size: 14px;
  }
  .van-tab--active {
    font-weight: 600;
  }
  .van-tabs__line {
    width: 14px;
    height: 6px;
    background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
    bottom: 20px;
  }
}
</style>
