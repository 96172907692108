<template>
  <div class="videoList overflow-y-auto">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="slideBox">
        <div v-for="item in list" :key="item.id">
          <div v-if="item.type">
            <ImgDecypt :src="item.cover" class="advBox" @click.native="toAdv(item)" />
            <div class="advTitle">
              <div class="title">
                {{ item?.title ? item?.title : '广告' }}
              </div>
            </div>
          </div>
          <VideoBox v-else @click.native="openVideo(item)" class="slideItem" :videoInfo="item" />
        </div>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { getSection } from '@/api/movies';
import PullRefresh from '@/components/PullRefresh';
import { AdType, getAdItem, jumpAdv } from '@/utils/getConfig';
import VideoBox from '@/components/VideoBox';
import ImgDecypt from '@/components/ImgDecypt';

export default {
  name: 'tagVideo',
  components: {
    PullRefresh,
    VideoBox,
    ImgDecypt,
  },
  props: {
    topicId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tagId: '',
      tagName: '',
      happinessList: [], // 列表
      list: [], // 真实列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
      advList: [], // 广告数据
    };
  },
  created() {
    this.tagId = this.$route.query.id;
    this.tagName = this.$route.query.name;
    this.getList();
    this.advList = getAdItem(AdType.COLUMN_MORE); // 获取轮播广告数据
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sectionID: this.topicId,
        sortType: this.type,
        playTimeType: '1',
      };
      try {
        let res = await this.$Api(getSection, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          if (type === 'refresh') {
            this.happinessList = [];
            this.list = [];
          }
          let list = res.data.videos ? res.data.videos : [];
          this.happinessList = this.happinessList.concat(list);
          if (this.happinessList.length == 0 && this.pageNumber == 1) {
            this.isNoData = true;
          }
          let advs = [].concat(this.advList);
          this.list = [].concat(this.happinessList);
          if (advs.length > 0) {
            this.list.forEach((item, index) => {
              if (index > 0 && index % 4 == 0) {
                let adv = advs.splice(0, 1);
                adv[0].type = 'adv';
                this.list.splice(index + index / 4 - 1, 0, ...adv);
                advs.push(...adv);
              }
            });
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
    toAdv(item) {
      jumpAdv(item);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 92px;
  width: 164px;
}
.videoList {
  height: calc(100vh - 44px);
  padding: 10px 20px 0;
  box-sizing: border-box;
  .slideBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 9px;
    grid-column-gap: 7px;
    .advBox {
      height: 92px;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
    }
    .advTitle {
      background-color: rgb(248, 248, 249);
      border-radius: 0 0 10px 10px;
      padding: 4px 8px 8px;
      box-sizing: border-box;
      height: 46px;
      .title {
        font-size: 12px;
        color: rgb(65, 65, 65);
        font-weight: 500;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .slideItem {
      width: 164px;
    }
  }
}
</style>
